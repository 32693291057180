<template>
  <div style="width: 5.8cm; text-align: center; font-family: arial; font-size: 22px; color: black">
    <p style="margin-bottom: 1px; font-weight: bold">SWIFT FULFILLMENT&nbsp;</p>
    <p style="margin-bottom: 1px; margin-top: 1px;font-size: 80px">#{{orderIndex}}</p>
    <p style="margin-bottom: 1px">==============&nbsp;</p>
    <p style="margin-bottom: 1px; font-weight: bold;margin-top: 1px;">{{clientName}}</p>
    <p style="margin-bottom: 1px; font-weight: bold;margin-top: 1px;">{{clientPhone}}</p>
    <p style="margin-bottom: 1px; margin-top: 1px;">{{clientAddress}}</p>
    <p style="margin-bottom: 1px;margin-top: 1px;">{{clientColonia}}</p>
    <p style="margin-bottom: 1px;margin-top: 1px;">==============&nbsp;</p>
    <vue-barcode style="margin-bottom: 0" :value="trkID" :options="{ displayValue: false, width: 1.38}" :tag="'svg'"></vue-barcode>
    <p style="margin-top: 0"><span style="background-color: black; color: white;-webkit-print-color-adjust: exact;font-size: 14px">TRK</span>
      <span style="font-size: 14px">&nbsp;{{trkID}}</span></p>
  </div>
</template>

<script>
import { auth, pickupSequence } from "../logic/firebase";

var guidesDate;
var orderObject;
var orderIndex = 1;
var clientName = '';
var clientPhone = '';
var clientAddress = '';
var clientColonia = '';
var trkID = '1'

export default {
  data() {
    return {
      guidesDate: guidesDate,
      orderObject: orderObject,
      orderIndex: orderIndex,
      clientName: clientName,
      clientPhone: clientPhone,
      clientAddress: clientAddress,
      clientColonia: clientColonia,
      trkID: trkID
    }
  },


  methods: {
    async queryDate(){
      await pickupSequence.doc(this.$route.query.date).get().then((doc) => {
        if(doc.exists){
          this.orderObject = doc.data().orderObject;
        }else{
          this.$router.push('/PrintGuidesSelector');
        }
      }).then(async () => {
        await this.populateAndPrintSequences()
      })
    },//queryDate

    async populateAndPrintSequences(){
      for(var i = 0; i < Object.keys(this.orderObject).length; i++){
        let current = this.orderObject[i];
        this.orderIndex = i + 1;
        this.clientName = current.displayName;
        this.clientPhone = current.phoneNumber;
        this.clientColonia = current.colonia;
        this.clientAddress = current.calle + ' ' + current.number;
        this.trkID = current.trkID.substring(3);
        await this.$nextTick(async () => {
          if(current.state === 'Recolección pendiente'){
            await window.print()
          }
        });
      }
      this.$router.push('/PrintGuidesSelector');
    }
  },


  mounted() {
    this.queryDate()
  },
}
</script>

<style rel='stylesheet' media="print">
@page{
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}
p{
  margin-block-start: 0em;
  margin-block-end: 0em;
}
body {color:black;}
</style>